<template>
<div class="">
    <div class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light">
      <div class="navbar-content">
        <div class="">
          <div class="sidebar-logo">
            <inertia-link :href="$routes.root()" class="main-title">Uniform Smart City Evaluation (USE)</inertia-link>
            <img src="@/images/logos/cperi.png" alt="cperi" style="width: 130px; margin: 3px; padding: 3px">
          </div>

          <hr class="hr-white">

          <div class="sidebar-items">
            <b-nav vertical class="w-85">
              <b-nav-text active>
                <inertia-link :href="$routes.root()">
                  <b-icon icon="house-fill"></b-icon>
                  Home
                </inertia-link>
              </b-nav-text>
              <b-nav-text active>
                <inertia-link :href="$routes.projects()">
                  <b-icon icon="binoculars-fill"></b-icon>
                  Projects
                </inertia-link>
              </b-nav-text>
              <b-nav-text active>
                <inertia-link :href="$routes.user_manual()">
                  <b-icon icon="book-fill"></b-icon>
                  User Manual
                </inertia-link>
              </b-nav-text>
            </b-nav>
          </div>

          <hr class="hr-white">
          <!--Modal form for KPIs/PSIs upload-->
          <div>
            <b-button style="background-color:transparent; border:0px; color:white; padding-left: 0;"  @click="$bvModal.show('importKPI')"><b-icon icon="plus-circle-fill" style="color:white"></b-icon>
              Upload Custom KPIs</b-button>
            
              <b-button style="background-color:transparent; border:0px; color:white; padding-left: 0;" @click="$bvModal.show('importPSI')"><b-icon icon="plus-circle-fill" style="color:white"></b-icon>
              Upload Custom PSIs</b-button>

            <b-modal id="importKPI" hide-footer>
              <template #modal-title>
                Upload custom KPIs
              </template>
              <div class="d-block text-center">
                <p>Import your custom KPI. A template can be found below.</p>
                <p><u>Save your file as CSV with comma delimeter</u></p>
                <a :href="this.$routes.download_kpi_template()">KPIs template</a>
              </div>
              <div class="form-wrapper">
                <form @submit.prevent="submit">
                    <input type="text" v-model="form.list_name" placeholder="Input KPI list name" required>
                    <input type="file" @input="form.uploaded_csv_file = $event.target.files[0]" accept=".csv" required>
                    <input type="hidden" value="12345">
                    <br>
                    <b-button class="mt-3" block @click="$bvModal.hide('importKPI')">Close</b-button>
                    <button type="submit" class="btn btn-primary mt-3">Submit</button>  
                </form>
              </div>
            </b-modal>
            <b-modal id="importPSI" hide-footer>
              <template #modal-title>
                Upload custom PSI
              </template>
              <div class="d-block text-center">
                <p>Import your custom PSI. A template is available below.</p>
                <p><u>Save your file as CSV with comma delimeter</u></p>
                <a :href="this.$routes.download_psi_template()">PSIs template</a>
              </div>
              <div class="form-wrapper">
                <form @submit.prevent="submit_psi">
                    <input type="text" v-model="form2.list_name" placeholder="Input PSI list name" required>
                    <input type="file" @input="form2.uploaded_csv_file = $event.target.files[0]" accept=".csv" required>
                    <input type="hidden" value="12345">
                    <br>
                    <b-button class="mt-3" block @click="$bvModal.hide('importPSI')">Close</b-button>
                    <button type="submit" class="btn btn-primary mt-3">Submit</button>  
                </form>
              </div>
            </b-modal>
          </div>

          <hr class="hr-white">
          <!--View KPIs/PSIs-->
          <div class="sidebar-subitems">
            <b-nav vertical class="w-85">
              <inertia-link :href="this.$routes.kpi_types()" style="text-decoration: none; color: white" >
                    KPIs List
                  </inertia-link>  
                  <inertia-link :href="this.$routes.psi_types()" style="text-decoration: none; color: white" >
                    PSIs List
                  </inertia-link>              
            </b-nav>
          </div>  
        </div>
      </div>
      <div class="navfooter">
       Developed by CPERI@CERTH
      </div>
      </div>
    <div class="main-content">
      <slot />
    </div>

    <div class="footer">
      <b-row align-h="end" no-gutters>
        <b-col cols="3">
          <inertia-link
              :href="$routes.destroy_user_session()"
              method="delete"
              as="button"
              class="logout-button"
          >Log out</inertia-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import MainMenu from '@/Shared/MainMenu'
export default {
  components: {
    MainMenu
  },
  data(){
    return{
       form: this.$inertia.form({
         uploaded_csv_file: null,
         list_name:null
       }),
       form2: this.$inertia.form({
        uploaded_csv_file: null,
        list_name: null
       })
    }
  },
  methods: {
    submit(){
       this.form.post(this.$routes.import_csv_path())
       this.form.list_name=""
       this.$bvModal.hide('importKPI')
    },
    submit_psi(){
      this.form2.post(this.$routes.import_psi_csv_path())
      this.form2.list_name= ""
      this.$bvModal.hide('importPSI')
    }
  }
}
</script>

<style lang="css" scoped>
.navfooter{
  color:#ffffff;
  position:absolute;
  bottom:10px;
}
  .footer {
    height: 50px;
    margin-left: 250px;
    background: #6679a5;
    width: 100%;
    min-height:50px;
    position: fixed;
    bottom: 0;

  }
  .sidebar-logo {
    text-align: center;
  }
  .sidebar-logo a {
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    color: white;
    text-align: center;
  }

  .sidebar-items .navbar-text a {
    color: white;
    font-size: 18px;
    text-decoration: none;
  }

  .sidebar-subitems .navbar-text {
    font-size: 14px;
    color: white;
  }

  .sidebar-subitems {
    margin-top: 25px;
  }

  .main-content {
    padding: 10px;
    margin-left: 250px;
    min-height: 100%;
    margin-bottom: 50px;
  }

  .navbar-content {
    position: relative;
    height: 100%;
  }

  .nav-item a {
    color: white;
    font-size: 18px;
    text-decoration: none;
  }

  .hr-white {
    color: white;
    opacity: 0.8;
  }

  .logout-button {
    background: indigo;
    color: #ffffff;
    border-radius: 5px;
    margin-top: 10px;
  }
  .form-wrapper{
    padding-top:30px;
  }
</style>
