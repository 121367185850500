<template>
  <div class="">
    <b-container class="mt-3">
      <b-row v-if="projects.length > 0">
        <b-col cols="12" class="text-end">
          <label class="comparison-label">
            <InfoIcon message="Click here to enable projects comparison. That is a very useful tooltip."></InfoIcon>
            Enable Projects Comparison (Beta)
            <input type="checkbox" v-model="compareProjects">
          </label>
        </b-col>
        <b-col>
          <div>
            <b-table
                striped
                hover
                sticky-header
                :fields="tableFields"
                :items="projects"
                class="deep-blue-header-table text-center"
            >
              <template #cell(created_at)="data">
                {{ new Date(data.item.created_at).toLocaleString() }}
              </template>
              <template #cell(updated_at)="data">
                {{ new Date(data.item.updated_at).toLocaleString() }}
              </template>
              <template #thead-top="data">
                <b-tr>
                  <b-th colspan="5" style="text-align: center; font-size: 20px;">Projects</b-th>
                </b-tr>
              </template>
              <template #cell(action)="data">
                <inertia-link :href="$routes.project(data.item.id)">
                  <b-button variant="primary" size="sm">Open</b-button>
                </inertia-link>
                <b-button variant="primary"  @click.prevent="show(data.item.id,kpi)" :id="data.item.id" size="sm">Load Project</b-button>
                <b-button @click.prevent="destroyProject(data.item.id)" class="btncolor" size="sm">Remove</b-button>
                <form @submit.prevent="submit(data.item.id)" v-if="data.item.id == visibleId" :id="data.item.id" style="margin-top:5px">
                  <input type="radio" v-model="uploadItem" value="kpi">Upload KPI
                  <input type="radio" v-model="uploadItem" value="psi">Upload PSI
                    <select  v-if="uploadItem=='kpi'" v-model="selected_kpi_file.fname" > 
                      <option disabled value="first" >-- Select KPI list --</option>
                      <option v-for="customfile in availableKpis" :value="{id:data.item.id, file_name:customfile}">{{customfile}}</option>
                  </select>
                  <select v-if="uploadItem=='psi'" v-model="selected_psi_file.fname" > 
                      <option disabled value="first" >-- Select PSI list --</option>
                      <option v-for="customfile in availablePsis" :value="{id:data.item.id, file_name:customfile}">{{customfile}}</option>
                  </select>
                  <b-button v-if="uploadItem=='psi' || uploadItem=='kpi'" type="submit" variant="primary" size="sm">Load</b-button>
                  <b-button variant="danger" @click.prevent="closeLoad()" size="sm"><b-icon icon="box-arrow-in-up"></b-icon></b-button>
                </form>
                <div v-if="$page.props.flash.alert && data.item.id == visibleId" class="alert" @click="closeDamn()">
                  <b-list-group class="mb-2">
                  <b-list-group-item variant="danger">
                    {{$page.props.flash.alert}}
                  </b-list-group-item>
                  </b-list-group>
                </div>
                <b-button v-if="compareProjects" @click.prevent="addToCompare(data.item.id, data.item.name)"
                          variant="link" style="color: forestgreen">Compare
                </b-button>
              </template>
            </b-table>
          </div>
        </b-col>

        <b-col cols="12">
          <ComparableProjectsList
            :projects-compare-list="projectsCompareList"
          ></ComparableProjectsList>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col>
          <p>No projects created yet.
            Please enter a project name and press the <strong>Add</strong> button to create a new project.</p>
        </b-col>
      </b-row>

      <AddNewProject :value="form"></AddNewProject>

    </b-container>
    <div v-if="$page.props.flash.alert" class="alert" @click="closeDamn()">
                  <b-list-group class="mb-2">
                  <b-list-group-item variant="danger">
                    {{$page.props.flash.alert}}
                  </b-list-group-item>
                  </b-list-group>
                </div>
  </div>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import InfoIcon from "../../Shared/InfoIcon";
import ComparableProjectsList from "./subcomponents/ComparableProjectsList";
import AddNewProject from "./subcomponents/AddNewProject";

export default {
  components: { InfoIcon, ComparableProjectsList, AddNewProject},
  layout: MainLayout,
  props: {
    projects: {
      type: Array,
      required: true
    },
    project: {
      type: Object,
      required: true
    },
    availableKpis: {
      type: Array,
      required: false
    },
    availablePsis: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      tableFields: ['id', 'name', 'created_at', 'updated_at', 'action'],
      form: this.project,
      compareProjects: false,
      projectsCompareList: [],
      selected_kpi_file: this.$inertia.form({
        fname: "first"
      }),
      selected_psi_file: this.$inertia.form({
        fname: "first"
      }),
      visibleId: 0,
      uploadItem: ""
    }
  },
  computed: {
  },
  methods: {
    destroyProject(id) {
      this.$inertia.delete(this.$routes.project(id), {
        onBefore: () => confirm('Are you sure you want to delete this project?')
      })
    },
    addToCompare(id, name) {
      const newProjectIndex = this.projectsCompareList.findIndex(p => p.id === id)
      if (newProjectIndex < 0 && this.projectsCompareList.length < 2) {
        this.projectsCompareList.push({ id, name })
      }
    },
    submit(id){
      if (this.selected_kpi_file.fname != "first"){
        this.selected_kpi_file.post(this.$routes.file_selection(id), {
          onBefore: () => confirm('Are you sure you want to continue with the selected KPI?'),
        })
        this.selected_kpi_file.fname = "first"
      }
      if (this.selected_psi_file.fname != "first"){
        this.selected_kpi_file.fname = "first"
        this.selected_psi_file.post(this.$routes.psi_file_selection(id), {
          onBefore: () => confirm('Are you sure you want to continue with the selected PSI?')
        })
        this.selected_psi_file.fname = "first"
      }
    },
    show(id){
        this.visibleId = id
        this.$page.props.flash.alert=""
    },
    closeLoad(){
      this.visibleId =0
    },
    closeDamn(){
      this.$page.props.flash.alert=""
    }
  }
}
</script>

<style lang="css" scoped>
.comparison-label {
  font-size: 15px;
  padding: 8px 10px 5px 10px;
  background: #2b28d2;
  color: white;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}

.comparison-container {
  padding: 10px 55px 10px 15px;
  border: 1px solid black;
  border-radius: 20px;
  background: #2926d6;
  color: white;
  margin-top: 15px;
  margin-bottom: 15px;
}

.comparison-button {
  color: black;
  background: #cfd7ff;
  padding: 5px;
  border-radius: 5px;
  text-decoration: none;
  margin-left: 10px;
}
.btncolor{
  background-color: orange;
  color: black;
}
</style>
