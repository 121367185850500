<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <h2 class="float-start">{{ clearAndCapitalize(project.name) }}</h2>
          <b-row class="float-end">
            <b-col cols="12">
              <inertia-link :href="this.$routes.project_kpi_analysis(project.id)" v-if="kpis.length > 0">
                <b-button variant="success">
                  SII SPI Analysis
                </b-button>
              </inertia-link>

              <inertia-link :href="this.$routes.project_psi_analysis(project.id)" v-if="psis.length > 0">
                <b-button class="btn-orange">
                  PPI Analysis
                </b-button>
              </inertia-link>
            </b-col>
          </b-row>
        </b-col>
        <hr>
      </b-row>

      <b-row class="mb-3">
        <b-col>
          <IgnoreWeightsSelector :project-id="project.id" :ignored-weights-server="ignoredWeightsServer"></IgnoreWeightsSelector>
        </b-col>
      </b-row>
      <div>
        <b-card no-body>
          <b-tabs pills card>
            <b-tab title="KPIs" active>
              <b-row>
                <b-col cols="6">
                  <h5>Your specified KPIs</h5>
                  <span v-if="kpis.find(kpi => !kpi.state)" class="text-muted">KPIs in red are in incomplete state and will not be considered in the analysis.</span>
                </b-col>
                <b-col cols="6" style="text-align: right">
                  <inertia-link :href="$routes.new_project_kpi(project.id)">
                    <b-button class="btn-deep-blue mb-2">Add KPI</b-button>
                  </inertia-link>
                </b-col>
              </b-row>
              <b-row>
               <hr>
              </b-row>
                <b-col cols="12" class="table">
                  <IndicatorsTable
                    :project-id="project.id"
                    indicator-type="kpi"
                    :headers="tableFieldsKpis"
                    :items="kpisWithState"
                    color="sand"
                  ></IndicatorsTable>
                </b-col>
            </b-tab>
            <b-tab title="PSIs">
              <b-row>
                <b-col><h5>Your specified PSIs</h5></b-col>
                <b-col style="text-align: right;">
                  <inertia-link :href="$routes.new_project_psi(project.id)">
                    <b-button class="btn-deep-blue mb-2">Add PSI</b-button>
                  </inertia-link>
                </b-col>
              </b-row>
              <b-row>
              <hr>
               <b-col class="table">
                  <IndicatorsTable
                    :project-id="project.id"
                    indicator-type="psi"
                    :headers="tableFieldsPsis"
                    :items="psis"
                    color="dark"
                  ></IndicatorsTable>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
    </div>        
      <b-row class="mt-2">
        <b-col>
          <inertia-link :href="this.$routes.projects()">Back to projects</inertia-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MainLayout from '@/Layouts/Main'
import IgnoreWeightsSelector from "./subcomponents/IgnoreWeightsSelector";
import IndicatorsTable from "./subcomponents/IndicatorsTable";

export default {
  layout: MainLayout,
  components: {
    IgnoreWeightsSelector,
    IndicatorsTable
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    kpis: {
      type: Array,
      required: true
    },
    psis: {
      type: Array,
      required: true
    },
    analysisJSON: {
      type: Array,
      required: false
    },
    analysisJSONPSIs: {
      type: Array,
      required: false
    },
    ignoredWeightsServer: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      tableFieldsKpis: [
        {key: 'id', label: 'KPI Id'},
        'name', 'aggregation_levels', 'dimension', 'sectors', 'created_at', 'action',
      ],
      tableFieldsPsis: [{key: 'id', label: 'PSI Id'}, 'name', 'call_impact', 'created_at', 'action']
    }
  },
  computed: {
    kpisWithState () {
      let kpis = []
      for (let i = 0; i < this.kpis.length; i++) {
        const kpi = this.kpis[i]
        if (!kpi.state)
          kpi['_rowVariant'] = 'danger'
        kpis.push(kpi)
      }
      return kpis
    }
  },
  methods: {
    onDeleteKpi (projectId, kpiId) {
      this.$inertia.delete(this.$routes.project_kpi(projectId, kpiId), {
        onBefore: () => confirm('Are you sure you want to delete this KPI?'),
      })
    },
    onDeletePsi (projectId, psiId) {
      this.$inertia.delete(this.$routes.project_psi(projectId, psiId), {
        onBefore: () => confirm('Are you sure you want to delete this PSI?'),
      })
    }
  }
}
</script>
<style>
.table{
  max-height:400px;
  overflow-y: auto;
} 
</style>
